import React from "react"

export default function About() {
  return (
    <div class="about">
    <p>Laboratory Umakino consists of two subjects.</p>
    <p>Subject 1 has a background in philosophy and physics. He is interested in filmmaking, interactive design, and music production.</p>
    <p>Subject 2 was trained in media and experimental filmmaking. Her interests are in architecture, cultural studies, and documentary arts.</p> 
    <p>Find us on <a href="https://space.bilibili.com/23640688">Bilibili</a> and <a href="https://www.youtube.com/channel/UC31NfkP9ICYWikcsgH2RhFw">YouTube</a>.</p>

    <p><a href="mailto:contact@labumakino.com"> Send us an email </a>, if you have a project idea or a work commision.</p>
    </div>
  )
}